import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey, faBank, faSignOutAlt , faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';

function BindNumber({userData}) {
	const [loading, setLoading] = useState(false);
  const [isOtpSent, sendOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [number, setNumber] = useState("");
  
  const handleChange = (event) => {
    setNumber(event.target.value);
  }
  
  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  }
 
 
 const handleSendOtp = (event) => {
 	
 event.target.disabled=true;
 
 const loadingToast = toast.loading('Sending Otp...');
 
     axios.get(`https://rupeebuzz.in/walletBackend/?sendOtp=${number}`, { withCredentials: true })
      .then(response => {
      	toast.dismiss(loadingToast);
      	if(response.data.status==true){
      	toast.success(response.data.message);
      	sendOtp(true);
      }else{
      	sendOtp(false);
      toast.error(response.data.message);
      event.target.disabled=false;
      }
      })
      .catch(error => {
       console.log("error");
       event.target.disabled=false;
       sendOtp(false);
       toast.error("Failed to send otp");
      });
      
      }
      
      const handleVerifyOtp = (event) => {
      	event.target.disabled=true;
      const loadingToast = toast.loading('Verifying Otp...');
     axios.get(`https://rupeebuzz.in/walletBackend/?verifyOtp=${otp}&number=${number}`, { withCredentials: true })
      .then(response => {
      	toast.dismiss(loadingToast);
      	if(response.data.status==true){
      toast.success(response.data.message);
      }else{
      	event.target.disabled=false;
      toast.error(response.data.message);
      }
        console.log(response.data);
      })
      .catch(error => {
       console.log("error");
       event.target.disabled=false;
       toast.error("Failed to verify otp");
      });
      
      };
      
      useEffect(() => {
    document.title = "Bind Mobile Number";
  }, []);
  
  return(
    <div style={{fontFamily: "'Kanit', sans-serif", backgroundColor: "#f5f5f5"}}>
        <nav style={{zIndex: "99"}} className="navbar shadow-sm bg-body-tertiary">
      <div className="container-fluid">
<div className="navbar-brand d-flex align-items-center">
          <img src="https://icons.veryicon.com/png/o/object/a-group-of-daily-life-icons/wallet-73.png" alt="Logo" width="40" height="40" className="d-inline-block align-text-top"/>
          
          <div style={{fontWeight: "500", fontSize: "23px"}} className="mx-2">
          RupeeBuzz
        </div>
        
        </div>
                  <img style={{borderRadius: "50%", border: "1px solid #81bfff", padding: "2px"}} src={userData.pic} alt="Logo" width="40" height="40" className="d-inline-block align-text-top"/>
      </div>
    </nav>
    
    <Toaster/>
    
  <div className="container p-3">
  
 
  <div style={{ display: isOtpSent ? 'none' : 'block' }} className="form-floating mb-3">
  <input type="number" onChange={handleChange} className="form-control" id="floatingInput" placeholder="Enter Mobile Number" />
  <label htmlFor="floatingInput">Mobile Number</label>
</div>
<div style={{ display: isOtpSent ? 'block' : 'none' }} className="form-floating mb-3">
  <input type="number" onChange={handleOtpChange} className="form-control" id="floatingInput" placeholder="Enter OTP" />
  <label htmlFor="floatingInput">OTP</label>
</div>

<button style={{ display: isOtpSent ? 'none' : 'block' }} onClick={handleSendOtp} className="btn btn-lg btn-outline-primary w-100"><FontAwesomeIcon icon={faKey} /> Send Otp</button>

<button style={{ display: isOtpSent ? 'block' : 'none' }} onClick={handleVerifyOtp} className="btn btn-lg btn-outline-primary w-100 mt-3"><FontAwesomeIcon icon={faArrowRight} /> Verify Otp</button>


    </div>
    </div>
    );
}

export default BindNumber;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faBank, faSignOutAlt , faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Loader from './Loader'; 
function TxnDetails({userData}) {
  const { id } = useParams();
  
  const [loading, setLoading] = useState(true);

  const [amount, setAmount] = useState("₹0.00");
  const [comment, setComment] = useState("Loading");
const [title, setTitle] = useState("Cashback Received");
const [tofrom, setToFrom] = useState("From");
const [name, setName] = useState("RupeeBuzz");
const [time, setTime] = useState("Today 12:00 PM");
const [closing, setClosing] = useState("₹0.00");
const [logo, setLogo] = useState("");
const [oid, setOid] = useState("RUPEEBUZZ000000001");

  useEffect(() => {
    
     axios.get(`https://rupeebuzz.in/walletBackend/?txn=${id}`, { withCredentials: true })
      .then(response => {
      	
      setAmount(response.data.amount);
      setComment(response.data.comment);
      setTitle(response.data.desc);
      setToFrom(response.data.crdr === "DR" ? "From" : "To");
      setName(response.data.crdr=="DR" ? response.data.from : response.data.to );
      setTime(response.data.time);
      setClosing(response.data.closing_balance);
      setOid(response.data.order_id);
      setLogo(response.data.logo);
      
      
        console.log(response.data);
      })
      .catch(error => {
       console.log("error");
      });
      
     document.title="RupeeBuzz | Transaction Details";
     
  }, []);
  
  
  
  return(
  
  <div style={{fontFamily: "'Kanit', sans-serif", backgroundColor: "#f5f5f5"}}>
        <nav style={{zIndex: "99"}} className="navbar shadow-sm bg-body-tertiary">
      <div className="container-fluid">
<div className="navbar-brand d-flex align-items-center">
          <img src="https://icons.veryicon.com/png/o/object/a-group-of-daily-life-icons/wallet-73.png" alt="Logo" width="40" height="40" className="d-inline-block align-text-top"/>
          
          <div style={{fontWeight: "500", fontSize: "23px"}} className="mx-2">
          RupeeBuzz
        </div>
        
        </div>
                  <img style={{borderRadius: "50%", border: "1px solid #81bfff", padding: "2px"}} src={userData.pic} alt="Logo" width="40" height="40" className="d-inline-block align-text-top"/>
      </div>
    </nav>
  <div className="container p-3">
  
  <div className="text-start mb-3">
  <Link to="/" style={{fontSize: "20px", color: "#393939"}} className="text-decoration-none bg-transparent mb-3"><FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
</div>
  
  <div className="txnbox">
  <div className="cbrcvd">
  {title}
  </div>
  <div className="amo mt-0 mb-2">
  ₹{amount} <img src="https://static.vecteezy.com/system/resources/previews/011/858/556/original/green-check-mark-icon-with-circle-tick-box-check-list-circle-frame-checkbox-symbol-sign-png.png" className="greentick"/>
  </div>
  
  <div className="comment">{comment}</div>
  
  <div className="amoword mt-3">
  {tofrom}
  </div>
  
  <div className="hello d-flex justify-content-between">
  <div className="from mr-auto">{name}</div>
  <img className="fromlogo" src={logo}/>
  </div>
  
  <hr/>
  <div className="amoword text-muted">
  {time}
  </div>
  
  <div className="amoword">
  Closing balance: ₹{closing}
  </div>
  
  <div className="amoword">
  Order ID: {oid}
  </div>
  
  
  
  </div>
  
  
  
  


    </div>
    </div>
    );
}

export default TxnDetails;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faBank, faSignOutAlt , faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';

function UpiWithdrawal({userData}) {
  
  const [loading, setLoading] = useState(false);
  const [subtn, setSub] = useState(false);
  
 const handleSubmit = async (event) => {
  	setSub(true);
  event.preventDefault();
  const formData = new FormData(event.target);
  setLoading(true);
  const loadingToast = toast.loading('Processing...');

  try {
    const response = await axios.post('https://rupeebuzz.in/walletBackend/?upiWithdrawal', formData, { withCredentials: true });
    toast.dismiss(loadingToast);
    setSub(false);
    console.log('Response:', response.data);
    if(response.data.status){
    	toast.success(response.data.message);
    	}else{
    	toast.error(response.data.message);
    
    }
  } catch (error) {
    toast.error('Something went wrong');
    setSub(false);
  } finally {
      setLoading(false);
    }
};

useEffect(() => {
    document.title = "RupeeBuzz | UPI Withdrawal";
  }, []);
  
  return(
    <div style={{fontFamily: "'Kanit', sans-serif", backgroundColor: "#f5f5f5"}}>
        <nav style={{zIndex: "99"}} className="navbar shadow-sm bg-body-tertiary">
      <div className="container-fluid">
<div className="navbar-brand d-flex align-items-center">
          <img src="https://icons.veryicon.com/png/o/object/a-group-of-daily-life-icons/wallet-73.png" alt="Logo" width="40" height="40" className="d-inline-block align-text-top"/>
          
          <div style={{fontWeight: "500", fontSize: "23px"}} className="mx-2">
          RupeeBuzz
        </div>
        
        </div>
                  <img style={{borderRadius: "50%", border: "1px solid #81bfff", padding: "2px"}} src={userData.pic} alt="Logo" width="40" height="40" className="d-inline-block align-text-top"/>
      </div>
    </nav>
    <Toaster/>
  <div className="container p-3">
  
  <div className="text-start mb-3">
  <Link to="/" style={{fontSize: "20px", color: "#393939"}} className="text-decoration-none bg-transparent mb-3"><FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
</div>
  <form onSubmit={handleSubmit}>
  <div className="form-floating mb-3">
        <input name="upi" type="email" className="form-control" id="floatingInput" placeholder="alpeshgohil@jio" />
        <label htmlFor="floatingInput">UPI ID</label>
      </div>
      <div className="form-floating">
        <input name="amount" type="number" className="form-control" id="floatingPassword" placeholder="Amount" />
        <label htmlFor="floatingPassword">Amount</label>
      </div>
      <button type="submit" disabled={subtn} className="btn btn-primary mt-3 w-100"><FontAwesomeIcon icon={faBank} /> Withdraw Now</button>
  </form>
    </div>
    </div>
    );
}

export default UpiWithdrawal;
import React from 'react';

function Login() {
  
  const handleGoogleLogin = () => {
    window.location.href = 'https://rupeebuzz.in/walletBackend?auth-google';
  };
  
  return (
    <section style={{fontFamily: "'Kanit', sans-serif"}}>
    <nav className="navbar bg-body-tertiary">
      <div className="container-fluid">
<div className="navbar-brand d-flex align-items-center">
          <img src="https://icons.veryicon.com/png/o/object/a-group-of-daily-life-icons/wallet-73.png" alt="Logo" width="40" height="40" className="d-inline-block align-text-top"/>
          
          <div style={{fontWeight: "500", fontSize: "23px"}} className="mx-2">
          RupeeBuzz
        </div>
        
        
        
        </div>
      </div>
    </nav>
    
    <div className="main-content">
    <h1 style={{fontWeight: "600", color: "#f0f0f0"}} className="">
    RupeeBuzz
    </h1>
    <h4 style={{fontSize: "17px"}} className="text-center text-light">
    Enjoy the convenience of transacting quickly and easily.
    </h4>
    
        <h4 style={{fontSize: "17px"}} className="text-center text-light">
    Instant withdrawal is a feature that enables users to quickly and effortlessly retrieve funds from their e-wallets in real-time.
    </h4>
    
    </div>
    
    
    <footer className="fixed-bottom">
    <div className="container text-center mb-3">
     <button onClick={handleGoogleLogin} style={{borderRadius: '30px', fontWeight: '500', borderWidth: "2px"}} className="btn border-lg border-primary border-lg btnshadow btn-lg btn-light mb-3"><svg className="mx-2 marbtm" xmlns="http://www.w3.org/2000/svg" width="18" height="18" preserveAspectRatio="xMidYMid" viewBox="0 0 256 262" id="google"><path fill="#4285F4" d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"></path><path fill="#34A853" d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"></path><path fill="#FBBC05" d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"></path><path fill="#EB4335" d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"></path></svg>Continue with Google</button>
    </div>
    </footer>
    
      <div className='air air1'></div>
  <div className='air air2'></div>
  <div className='air air3'></div>
  <div className='air air4'></div>
</section>

  );
}

export default Login;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function Home({userData}) {
	
	const navigate = useNavigate();
  const [myBalance, setBalance] = useState("₹0.00");
  const [transactions, setTransactions] = useState([]);
  
    const [loading, setLoading] = useState(false);

  const LogoutUser = async () => {
    setLoading(true);
    try {
      // Display loading toast
      const loadingToast = toast.loading('Logging out...');

      // Simulate API call (replace this with your actual API call)
      const data = await axios.get('https://rupeebuzz.in/walletBackend/?signout', { withCredentials: true });
      
      // Hide loading toast
      toast.dismiss(loadingToast);

      // Show success toast
      toast.success('User logged out successfully!');
      
      console.log(data);
    } catch (error) {
      // Show error toast
      toast.error('Error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  

 
  const reloadBalance = async () => {
      	setLoading(true);
      const loadingToast = toast.loading('Updating balance...');
      	axios.get('https://rupeebuzz.in/walletBackend/?getBalance', { withCredentials: true })
      .then(response => {
        const balance=response.data.balance;
        const balances = Number(balance).toFixed(2); 
        setBalance("₹"+balances);
        toast.success('Balance updated successfully!');
        toast.dismiss(loadingToast);
      })
      .catch(error => {
        setBalance("₹0.00");
        toast.error('Failed to load balance!');
        toast.dismiss(loadingToast);
      });
      setLoading(false);
      }
  
 
  useEffect(() => {
    axios.get('https://rupeebuzz.in/walletBackend/?getBalance', { withCredentials: true })
      .then(response => {
        const balance=response.data.balance;
        const balances = Number(balance).toFixed(2); 
        
        setBalance("₹"+balances);
      })
      .catch(error => {
        setBalance("₹0.00");
      });
     
     
     axios.get('https://rupeebuzz.in/walletBackend/?showTxn', { withCredentials: true })
      .then(response => {
        console.log(response.data);
        setTransactions(response.data);
      })
      .catch(error => {
        setTransactions([]);
      });
     
     document.title="RupeeBuzz | Home";
     
     
  }, []);
  
  return(
    <div style={{fontFamily: "'Kanit', sans-serif", backgroundColor: "#f5f5f5"}}>
        <nav style={{zIndex: "99"}} className="navbar shadow-sm bg-body-tertiary">
      <div className="container-fluid">
<div className="navbar-brand d-flex align-items-center">
          <img src="https://icons.veryicon.com/png/o/object/a-group-of-daily-life-icons/wallet-73.png" alt="Logo" width="40" height="40" className="d-inline-block align-text-top"/>
          
          <div style={{fontWeight: "500", fontSize: "23px"}} className="mx-2">
          RupeeBuzz
        </div>
        
        </div>
                  <img style={{borderRadius: "50%", border: "1px solid #81bfff", padding: "2px"}} src={userData.pic} alt="Logo" width="40" height="40" className="d-inline-block align-text-top"/>
      </div>
    </nav>
  <div className="container p-3">
  
  <Toaster/>
  
  <div class="row">
    <div class="col text-right">  <h5 className="mt-0">
  Hello
  </h5>
  <h4 className="text-muted" style={{marginTop: "-12px"}}>
 {userData.name}
  </h4></div>
    <div class="col text-end">
    
    <button className="btn btn-danger btn-sm" onClick={LogoutUser} disabled={loading}>
        {loading ? <FontAwesomeIcon icon={faSignOutAlt} /> : <FontAwesomeIcon icon={faSignOutAlt} />}
      </button>
    
    </div>
  </div>
  
  
  <div className="gjcard overflow-hidden">
  <img src="./rupee.png" style={{height: "80px", width: "80px", position: "absolute", opacity: "0.7", right: "10px", top: "6px"}}/>
  <div className="d-flex align-items-center">
  <div className="balance mx-1">
 {myBalance}
  </div>
  <div onClick={reloadBalance} className="balance-reload">
  <FontAwesomeIcon icon={faSync} />
  </div>
  </div>
  <div className="mobile text-muted mx-1">
  {userData.mobile_number}
  </div>
  </div>
  
  <div className="withdraw-list p-2 mt-2">
  
  <Link to="upi" className="text-decoration-none">
  <div className="withdraw-label">
  <img style={{borderRadius: "50%", border: "2px solid #ffbb02", padding: "2px"}} src="https://pixlok.com/wp-content/uploads/2021/01/UPI_logo_PNG.jpg" alt="UPI" width="50" height="50"/>
  <span className="text-muted">UPI</span>
  </div>
  </Link>
  
  <Link to="bank" className="text-decoration-none">
  <div className="withdraw-label">
  <img style={{borderRadius: "50%", border: "2px solid #ffbb02", padding: "2px"}} src="https://i.pinimg.com/736x/f1/a8/d5/f1a8d57036529808ede17992a51ab5cb.jpg" alt="Bank" width="50" height="50"/>
  <span className="text-muted">Bank</span>
  </div>
  </Link>
  
  <Link to="recharge" className="text-decoration-none">
  <div className="withdraw-label">
  <img style={{borderRadius: "50%", border: "2px solid #ffbb02", padding: "2px"}} src="https://img.freepik.com/premium-vector/check-mark-with-phone-icon-like-apply-now-confirm-flat-trend-modern-logotype-graphic-web-outline-design-element-isolated-white_995545-241.jpg?w=740" alt="Mobile" width="50" height="50"/>
  <span className="text-muted">Recharge</span>
  </div>
    </Link>
    
     <Link to="gplay" className="text-decoration-none">
  <div className="withdraw-label">
  <img style={{borderRadius: "50%", border: "2px solid #ffbb02", padding: "2px"}} src="https://static.vecteezy.com/system/resources/previews/017/395/379/non_2x/google-play-store-mobile-apps-logo-free-png.png" alt="Gplay" width="50" height="50"/>
  <span className="text-muted">GPlay</span>
  </div>
  </Link>
  
  
  </div>
  
  
  
  
  <hr style={{ borderTop: '2.5px solid #ee4e00', marginTop: 0, marginBottom: 0, opacity: "0.80", borderRadius: "50%"}} />
<hr style={{ borderTop: '2.5px solid #007dee', marginTop: 0, opacity: "0.80", borderRadius: "50%"}} />
  
  <div className="card shadow-sm">
  <div className="card-body">
  <h6 className="text-muted mb-3">
  Transactions History
  </h6>
  
  {transactions === "" ? (
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-auto">
        <img src="https://cdn-icons-png.flaticon.com/128/3985/3985004.png" style={{ height: "100px", width: "100px" }} />
      </div>
    </div>
    <div className="text-center">No Transactions</div>
  </div>
) : (
  transactions.map((transaction, index) => (
    <Link key={transaction.id} to={`details/${transaction.order_id}`} className="text-decoration-none text-dark">
      <div className="txndetails mb-0">
        <img style={{ borderRadius: "50%", border: "2px solid #ffbb02", padding: "2px" }} src={transaction.logo} alt="Gplay" width="40" height="40" />
        <div className="txns">
          <div className="desc">
            {transaction.desc}
          </div>
          <div className="time">
            {transaction.time}
          </div>
        </div>
        <div className={`txnamount text-${transaction.crdr === "DR" ? "success" : "danger"}`}>{transaction.crdr === "DR" ? "+" : "-"}₹{transaction.amount}</div>
      </div>
      {index !== transactions.length - 1 && <hr style={{ borderTop: "1px solid #dddddd", marginTop: "10px", opacity: "0.80", borderRadius: "50%" }} />}
    </Link>
  ))
)}
  
  
    </div>
    </div>
  
  
    </div>
    </div>
    );
}

export default Home;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faBank, faSignOutAlt , faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';


function GPlay({userData}) {
	
	
  useEffect(() => {
    document.title = "RupeeBuzz | Google Play Redeem";
  }, []);

  return(
    <div style={{fontFamily: "'Kanit', sans-serif", backgroundColor: "#f5f5f5"}}>
        <nav style={{zIndex: "99"}} className="navbar shadow-sm bg-body-tertiary">
      <div className="container-fluid">
<div className="navbar-brand d-flex align-items-center">
          <img src="https://icons.veryicon.com/png/o/object/a-group-of-daily-life-icons/wallet-73.png" alt="Logo" width="40" height="40" className="d-inline-block align-text-top"/>
          
          <div style={{fontWeight: "500", fontSize: "23px"}} className="mx-2">
          RupeeBuzz
        </div>
        
        </div>
                  <img style={{borderRadius: "50%", border: "1px solid #81bfff", padding: "2px"}} src={userData.pic} alt="Logo" width="40" height="40" className="d-inline-block align-text-top"/>
      </div>
    </nav>
  <div className="container p-3">
  
  <div className="text-start mb-3">
  <Link to="/" style={{fontSize: "20px", color: "#393939"}} className="text-decoration-none bg-transparent mb-3"><FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
</div>
  
  <div className="form-floating mb-3">
        <input readonly="true" type="email" className="form-control" id="floatingInput" placeholder="Enter your email" />
        <label htmlFor="floatingInput">Email address</label>
      </div>
      <div className="form-floating">
        <input readonly="true" type="password" className="form-control" id="floatingPassword" placeholder="Amount" />
        <label htmlFor="floatingPassword">Amount</label>
      </div>
      <button className="btn btn-primary mt-3 w-100"><FontAwesomeIcon icon={faArrowRight} /> Redeem Now</button>
  
    </div>
    </div>
    );
}

export default GPlay;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './Login';
import Home from './Home';
import TxnDetails from './TxnDetails';
import Bank from './Bank';
import GPlay from './GPlay';
import BindNumber from './BindNumber';
import MobileRecharge from './MobileRecharge';
import Loader from './Loader'; // Import your loader component
import UpiWithdrawal from './UpiWithdrawal'; // Import UpiWithdrawal component
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isBinded, bindNumber] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Check if the user is logged in by making a request to the backend API
    axios.get('https://rupeebuzz.in/walletBackend/?user-profile', { withCredentials: true })
      .then(response => {
        setIsLoggedIn(true);
        bindNumber(response.data.mobile_number=="" ? false : true );
        setUserData(response.data);
      })
      .catch(error => {
        setIsLoggedIn(false);
        bindNumber(false);
        setUserData(null);
      })
      .finally(() => setLoading(false));

   setInterval( () => {
    axios.get('https://rupeebuzz.in/walletBackend/?user-profile', { withCredentials: true })
      .then(response => {
        setIsLoggedIn(true);
        bindNumber(response.data.mobile_number=="" ? false : true );
        setUserData(response.data);
      })
      .catch(error => {
        setIsLoggedIn(false);
        bindNumber(false);
        setUserData(null);
      })
      .finally(() => setLoading(false));
 }, 5000);
 
 
  }, []);

  return (
    <div className="app-container">
      <div className={`overlay ${loading ? 'blur' : ''}`} />
      { loading ? ( // Show loader while loading
        <Loader />
      ) : (
        <Router>
          <Routes>
          <Route
              path="/"
              element={isLoggedIn ? ( isBinded ? <Home userData={userData} /> : <BindNumber userData={userData} /> ) : <Login />}
            />
                      <Route
              path="/details/:id"
              element={isLoggedIn ? ( isBinded ? <TxnDetails userData={userData} /> : <BindNumber userData={userData} /> ) : <Login />}
            />
            <Route
              path="/login"
              element={isLoggedIn ? ( isBinded ? <Home userData={userData} /> : <BindNumber userData={userData} /> ) : <Login />}
            />
            <Route
              path="/upi"
              element={isLoggedIn ? ( isBinded ? <UpiWithdrawal userData={userData} /> : <BindNumber userData={userData} /> ) : <Login />}
            />
            <Route
              path="/bank"
              element={isLoggedIn ? (isBinded ? <Bank userData={userData} /> : <BindNumber userData={userData} />) : <Login />}
            />
            <Route
              path="/recharge"
              element={isLoggedIn ? (isBinded ? <MobileRecharge userData={userData} /> : <BindNumber userData={userData} />) : <Login />}
            />
            
                        <Route
              path="/gplay"
              element={isLoggedIn ? ( isBinded ? <GPlay userData={userData} /> : <BindNumber userData={userData} />) : <Login />}
            />
            
          </Routes>
        </Router>
      )}
    </div>
  );
};

export default App;